import convert from 'color-convert';

// Function to convert hex color to RGBA format
export const hexToRGBA = (hex, opacity) => {
  const cleanHex = hex.replace("#", "");
  const red = parseInt(cleanHex.substring(0, 2), 16);
  const green = parseInt(cleanHex.substring(2, 4), 16);
  const blue = parseInt(cleanHex.substring(4, 6), 16);

  return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
};

export function isGreaterThan24Hours(date1, date2) {
  if (!date1 || !date2) {
    return false;
  }
  const diffInMilliseconds = Math.abs(new Date(date2) - new Date(date1));
  const hours = diffInMilliseconds / (1000 * 60 * 60); // convert milliseconds to hours

  return hours > 24;
}

export const lightenColor = (color, amount) => {
  // Convert hex color to RGB
  const rgb = convert.hex.rgb(color);

  // Convert RGB to HSB
  const hsb = convert.rgb.hsv(rgb);

  // Adjust the brightness (b) value
  const newB = Math.min(hsb[2] + amount, 100); // Ensure the brightness doesn't exceed 100

  // Set the new brightness (b) value and fixed saturation (s)
  const newHsb = [hsb[0], 10, newB];

  // Convert HSB to RGB
  const newRgb = convert.hsv.rgb(newHsb);

  // Convert RGB to hex color
  const newHex = convert.rgb.hex(newRgb);

  return `#${newHex}`;
};
