import React, { useEffect, useState } from "react";
import instance from "../extras/baseUrl";

const MultiLevel = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const gameSettingId = urlParams.get("gameSettingId");
  const campaignId = urlParams.get("campaignId");
  const clientId = urlParams.get("clientId");
  const userId = urlParams.get("userId");
  const token = urlParams.get("token");
  const [completed, setCompleted] = useState({
    tracker: false,
    settings: false,
    stats: false,
  });

  const [settings, setSettings] = useState({});
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(true);

  const eventTracker = async () => {
    try {
      const res = instance.post(
        "/events/multilevel/track",
        {
          clientId: clientId,
          campaignId: campaignId,
          nudgeUserId: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(await (await res).data);
      setSettings((prev) => ({
        ...prev,
        ["tracker"]: true,
      }));
    } catch (err) {
      window?.ReactNativeWebView?.postMessage("close");
      window?.app?.postMessage("close");
      window?.FlutterInappwebview?.callHandler("app", "close");
    }
  };

  const loadStats = async () => {
    const res = await instance.get(
      `/stats/multilevel?clientId=${clientId}&campaignId=${campaignId}&nudgeUserId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(res?.data?.data)
    if (res?.data?.data == null) {
      console.log("close")
      window?.ReactNativeWebView?.postMessage("close");
      window?.app?.postMessage("close");
      window?.FlutterInappwebview?.callHandler("app", "close");
    }
    setStats(res?.data?.data);
    setSettings((prev) => ({
      ...prev,
      ["stats"]: true,
    }));
  };

  const loadSettings = async () => {
    const res = await instance.get(
      `/game_settings?gameKey=multilevel&gameSettingId=${gameSettingId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const gameSetting = res.data.data.gameSetting;
    setSettings(res.data.data.gameSetting);
    setLoading(false);
    setSettings((prev) => ({
      ...prev,
      ["settings"]: true,
    }));
  };

  useEffect(() => {
    if (gameSettingId !== null && !completed?.settings) {
      loadSettings();
    }
    if (clientId && campaignId && userId) {
      if (!completed?.tracker) {
        eventTracker();
      }
      if (!completed?.stats) {
        loadStats();
      }
    }
  }, [gameSettingId, clientId, campaignId, userId]);

  console.log(stats);

  return (
    <div
      className="flex flex-col relative items-center h-full min-h-screen"
      style={{
        backgroundColor: settings?.props?.screen1?.bgColor,
      }}
    >
      <div className="h-48 py-8 px-8 w-full rounded-b-[40%] bg-gray-400">
        <div
          className="font-semibold"
          style={{
            color: settings?.props?.screen1?.heading?.fontColor,
            fontWeight: settings?.props?.screen1?.heading?.fontStyle,
          }}
        >
          {settings?.props?.screen1?.heading?.text}
        </div>
        <div
          style={{
            color: settings?.props?.screen1?.caption?.fontColor,
            fontWeight: settings?.props?.screen1?.caption?.fontStyle,
          }}
        >
          {settings?.props?.screen1?.caption?.text}
        </div>
        <div
          className="relative w-full h-4 rounded-full"
          style={{
            backgroundColor: settings?.props?.screen1?.progressBarColor1,
          }}
        >
          <div
            className="absolute inset-0 h-full w-[10%] rounded-full"
            style={{
              backgroundColor: settings?.props?.screen1?.progressBarColor2,
            }}
          />
        </div>
      </div>
      <div className="-mt-20 px-8 w-full space-y-4">
        {stats?.tasks &&
          settings?.tasks?.map((task, index) => (
            <div
              className=" w-full shadow-md rounded-xl"
              style={{
                backgroundColor: settings?.props?.screen1?.taskCardColor,
              }}
            >
              <div key={index} className="flex items-center gap-4 py-4 px-6">
                <img
                  className="w-16 h-16 rounded-full "
                  src={
                    stats?.tasks[index]?.completed
                      ? "/assets/icon-unlocked.png"
                      : "/assets/icon-locked.png"
                  }
                />
                <div className="ml-4">
                  <div
                    className="text-lg font-semibold"
                    style={{
                      color: settings?.props?.screen1?.taskHeading?.fontColor,
                      fontWeight: settings?.props?.screen1?.taskHeading?.fontStyle,
                    }}
                  >
                    Task {index + 1}
                  </div>
                  <div
                    className="text-sm"
                    style={{
                      color: settings?.props?.screen1?.taskCaption?.fontColor,
                      fontWeight: settings?.props?.screen1?.taskCaption?.fontStyle,
                    }}
                  >
                    {task?.taskDesc}
                  </div>
                  <div
                    className={`${
                      stats?.tasks[index]?.completed
                        ? "bg-green-300 text-white"
                        : "bg-yellow-300 text-yellow-500"
                    }  mt-1 px-2 py-1 w-fit rounded-md text-sm bg-opacity-40 `}
                  >
                    {stats?.tasks[index]?.completed ? "Completed" : "Pending"}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default MultiLevel;
