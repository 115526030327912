import React, { useState, useEffect, useRef } from "react";
import Check from "@heroicons/react/24/outline/CheckIcon";
import XMark from "@heroicons/react/24/outline/XMarkIcon";
import HowToPlay from "./HowToPlay";
import { useWindowSize } from "react-use";
import Confetti from "react-confetti";
import bg1 from "../../assets/bg1.png";
import axios from "axios";
import instance from "../../extras/baseUrl";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Loader from "../../components/Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import ProgressiveImage from "react-progressive-graceful-image";
import "react-lazy-load-image-component/src/effects/blur.css";
import { hexToRGBA, lightenColor } from "../../extras/utils";
import gifAnimation from "../../assets/coins-upanddown.gif";
import ConfettiExplosion from "react-confetti-explosion";

const QNA = [
  {
    question: "This is where the question1 is?",
    image:
      "https://images.unsplash.com/photo-1597274324473-c3ced481af9c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    options: ["Answer 1", "Answer 2", "Answer 3", "Answer 4"],
    correctAns: "Answer 4",
  },
  {
    question: "This is where the question2 is?",
    image:
      "https://images.unsplash.com/photo-1616615965190-08884c4d85c4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8YWRkaWRhc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
    options: ["Answer 1", "Answer 2", "Answer 3", "Answer 4"],
    correctAns: "Answer 4",
  },
];

const optionss = ["A", "B", "C", "D"];

const Quiz = () => {
  const { width, height } = useWindowSize();

  const [active, setActive] = useState(0);
  const [answers, setAnswers] = useState({});
  const [completed, setCompleted] = useState(false);
  const [howToPlay, setHowToPlay] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showCoins, setShowCoins] = useState(false);
  const [coinKey, setCoinKey] = useState(0);
  const [coins, setCoins] = useState(0);

  // useRef hooks
  const imageRef = useRef(null);

  // Navigation Hook
  const navigate = useNavigate();

  // useState hooks
  const [config, setConfig] = useState({});
  const [response, setResponse] = useState([]);
  const [reward, setReward] = useState({});
  const [loading, setLoading] = useState(false);

  const [divHeight, setDivHeight] = useState();

  // get query params from the url
  const urlParams = new URLSearchParams(window.location.search);
  const gameZoneId = urlParams.get("gameZoneId");
  const nudgeUserId = urlParams.get("nudgeUserId");
  const gameSettingId = urlParams.get("gameSettingId");
  const key = urlParams.get("key");
  const token = urlParams.get("token");

  const getGameSettings = async () => {
    try {
      const res = await instance.get(
        `/gamezone/gameSettings?gameSettingId=${gameSettingId}&gameKey=${key}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setConfig(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const PreRequest = async () => {
    try {
      const payload = {
        nudgeUserId: nudgeUserId,
        gameZoneId: gameZoneId,
        gameSettingId: gameSettingId,
      };
      const res = await instance.post(
        `/game_zone_quiz/pre?type=gamezone`,
        payload,
        {
          headers: {
            Authorization: `Bearerv ${token}`,
          },
        }
      );
      console.log(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const SubmitResponse = async (responseValue) => {
    try {
      const payload = {
        nudgeUserId: nudgeUserId,
        gameZoneId: gameZoneId,
        gameSettingId: gameSettingId,
        responses: responseValue,
      };
      const res = await instance.post(
        `/game_zone_quiz/post?type=gamezone`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(res.data);
      setReward(res.data.data.reward);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (token && nudgeUserId && gameSettingId && gameZoneId) {
      PreRequest();
    }
  }, [token, nudgeUserId, gameSettingId, gameZoneId]);

  useEffect(() => {
    if (token && key && gameSettingId) {
      getGameSettings();
    }
  }, [token, key, gameSettingId]);

  const handleClick = async (value) => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    if (value === config?.quizzes[active]?.correctOption) {
      setCoins(
        (prev) =>
          prev +
          config.quizzes[active].reward?.quantity *
            config.quizzes[active].reward?.rewardId?.value
      );
      setShowCoins(true);
      setCoinKey((prev) => prev + 1);
      setTimeout(() => {
        setShowCoins(false);
      }, 2000);
    }
    const payload = {
      question: config?.quizzes[active]?.question,
      option: value,
      correctOption: config?.quizzes[active]?.correctOption,
      reward: config?.quizzes[active]?.reward,
    };
    console.log(response);
    var newResponses = response;
    newResponses.push(payload);
    setResponse(newResponses);
    setAnswers({
      ...answers,
      [active]: value,
    });
    if (active + 1 < config?.quizzes?.length) {
      setTimeout(() => {
        const img = new Image();
        img.src = config?.quizzes[active + 1]?.imageUrl;
        img.onload = () => {
          setActive((prev) => prev + 1);
        };
        // console.log("IN TIMEOUT");
        setSubmitting(false);
        // setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 2800);
        // console.log("IN TIMEOUT");
      }, 2600);
      return;
    }
    setTimeout(() => {
      SubmitResponse(newResponses);
      setIsLoading(true);
      setCompleted(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 2800);
    }, 2600);
  };

  const handleSkip = () => {
    if (active + 1 !== config?.quizzes?.length) {
      setActive((prev) => prev + 1);
      return;
    }
    SubmitResponse(response);
    setCompleted(true);
  };

  useEffect(() => {
    const img = new Image();
    img.src = gifAnimation;
    img.onload = () => {};
  }, []);

  useEffect(() => {
    if (!howToPlay) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1200);
    }
  }, [howToPlay]);

  useEffect(() => {
    window?.ReactNativeWebView?.postMessage(window.location.href);
  }, []);

  useEffect(() => {
    const divElement = imageRef.current;
    const divElement2 = document.querySelector(".divRef")?.clientHeight;
    const headerElement = document.querySelector(".header")?.clientHeight;
    if (divElement || divElement2) {
      const windowHeight = window.innerHeight;
      const divHeight = divElement2 || divElement.clientHeight;
      var divHeightInVh =
        ((divHeight + 150 + headerElement) / windowHeight) * 100;
      divHeightInVh = 100 - divHeightInVh;
      console.log("Div height:", divHeightInVh);
      setDivHeight(`${divHeightInVh}vh`);
    }
  }, [imageRef.current, window.innerHeight]);

  if (howToPlay) {
    return <HowToPlay settings={config?.startScreen} setShow={setHowToPlay} />;
  }

  console.log(divHeight);

  return !completed ? (
    <>
      {showCoins && (
        <div className="fixed flex justify-center  items-center inset-0 z-50">
          <ConfettiExplosion
            force={0.6}
            duration={3000}
            particleCount={160}
            width={1000}
          />
        </div>
        //   <img
        //     key={coinKey}
        //     className="w-36 h-auto"
        //     src={gifAnimation}
        //     alt=""
        //   />
      )}
      <motion.div
        // initial={{ x: "100%" }}
        // animate={{ x: 0, transition: { duration: 0.4 } }}
        // exit={{ x: "-100%", transition: { duration: 0.4 } }}
        style={{
          backgroundColor: config?.quizzes[active]?.bgColor,
          backgroundImage: "url(" + config?.quizzes[active]?.bgImageUrl + ")",
        }}
        className="flex relative font-fieldwork10 flex-col select-none
        bg-[#6e6e6e23] w-full min-h-screen items-center justify-between w-full
        px-4 py-6 space-y-4"
      >
        {/* <Loader isLoading={isLoading} /> */}
        <div className="flex flex-col items-center space-y-4 w-full">
          {/* Question No counts */}
          <div className="header flex relative justify-center items-center w-full">
            <div
              style={{
                color: config?.primaryColor,
                backgroundColor: lightenColor(config?.primaryColor, 100),
              }}
              className="bg-purple-200 text-purple-600 px-3 rounded-md font-medium text-base z-10 absolute left-2 flex items-center"
            >
              {coins}{" "}
              <img
                className="h-6 w-6 object-contain"
                src="/assets/STUMBL-coin.gif"
                alt=""
              />
            </div>
            <div
              style={{
                color: config?.primaryColor,
                backgroundColor: lightenColor(config?.primaryColor, 100),
              }}
              className="bg-purple-200 text-purple-600 px-3 rounded-md font-medium text-base"
            >
              {active + 1}/{config?.quizzes?.length}
            </div>
            <button
              style={{
                color: config?.primaryColor,
              }}
              onClick={handleSkip}
              className="z-10 absolute right-0 ml-auto text-purple-600 text-sm"
            >
              SKIP
            </button>
          </div>
          {/* Question */}
          <div className="font-bold text-xl">
            {config?.quizzes[active]?.question}
          </div>
          <div
            className={`flex h-auto justify-center rounded-lg w-full`}
            style={{
              height: divHeight,
            }}
          >
            <LazyLoadImage
              effect="blur"
              className={`rounded-lg w-full h-[inherit] object-contain`}
              // style={{
              //   maxHeight: divHeight,
              // }}
              src={config?.quizzes[active]?.imageUrl}
              alt="image"
            />
          </div>
        </div>
        <div
          ref={imageRef}
          className="divRef space-y-3 w-full absolute bottom-[4vh] px-4"
        >
          {config?.quizzes[active]?.options?.map((option, index) => (
            <div
              key={index}
              onClick={() => handleClick(option)}
              style={{
                backgroundColor:
                  answers[active] === option
                    ? config?.quizzes[active].correctOption === option
                      ? "#06ab3e"
                      : "red"
                    : "",
                color:
                  answers[active] === option
                    ? config?.quizzes[active].correctOption === option
                      ? "white"
                      : "white"
                    : "#3f3f46",
                borderWidth:
                  answers[active] === option
                    ? config?.quizzes[active].correctOption === option
                      ? 0
                      : 0
                    : 2,
              }}
              className="border-2 px-3 cursor-pointer transition-colors duration-200 border-zinc-300 bg-white h-14 w-full rounded-xl flex items-center space-x-2"
            >
              {answers[active] === option ? (
                config?.quizzes[active].correctOption === option ? (
                  <div className="h-7 w-7 rounded-full bg-white flex items-center justify-center text-xs font-semibold">
                    <Check className="w-4 h-4 text-[#06ab3e]" />
                  </div>
                ) : (
                  <div className="h-7 w-7 rounded-full bg-white flex items-center justify-center text-xs font-semibold">
                    <XMark className="w-4 h-4 text-red-500" />
                  </div>
                )
              ) : (
                <div className="h-7 w-7 rounded-full border border-black flex items-center justify-center text-xs font-semibold">
                  {optionss[index]}
                </div>
              )}
              <span className="">{option}</span>
            </div>
          ))}
        </div>
      </motion.div>
    </>
  ) : (
    <>
      <Loader bgColor={config?.endScreen?.bgColor || config?.quizzes[active]?.bgColor} isLoading={isLoading} />
      {reward?.value !== 0 && completed && (
        <Confetti
          height={height - 10}
          width={width}
          recycle={false}
          tweenDuration={100}
          numberOfPieces={300}
        />
      )}

      <motion.div
        // initial={{ x: "100%" }}
        // animate={{ x: 0, transition: { duration: 0.4 } }}
        // exit={{ x: "-100%", transition: { duration: 0.4 } }}
        style={{
          backgroundColor: config?.endScreen?.bgColor,
          backgroundImage: "url(" + config?.endScreen?.bgImageUrl + ")",
        }}
        className="flex bg-no-repeat bg-center bg-contain font-inter flex-col items-center select-none max-w-lg mx-auto min-h-screen space-y-6"
      >
        <div className="flex flex-col items-center justify-between w-full select-none max-w-lg mx-auto min-h-screen py-[5vh] px-4 space-y-6">
          <div className="flex flex-col items-center pt-6 space-y-6">
            <div className="flex flex-col items-center  space-y-2">
              {completed && (
                <>
                  {reward?.value != 0 ? (
                    <h1
                      style={{
                        color: config?.primaryColor,
                      }}
                      className="text-[3.6vh] font-semibold text-purple-800"
                    >
                      {config?.endScreen?.heading}
                    </h1>
                  ) : (
                    <h1
                      style={{
                        color: config?.primaryColor,
                      }}
                      className="text-[3.6vh] font-semibold text-purple-800"
                    >
                      {config?.endScreen?.lostHeading}
                    </h1>
                  )}
                </>
              )}
              <div className="w-full max-h-[26vh] h-full">
                <img
                  className="w-full max-h-[26vh] object-contain"
                  src={config?.endScreen?.imageUrl}
                  alt=""
                />
              </div>
            </div>
            <div className="space-y-1">
              <h3 className="text-center text-[2.4vh] font-bold">
                {config?.endScreen?.subHeading}
              </h3>
              <div
                style={{
                  color: config?.primaryColor,
                }}
                className="text-purple-800 text-[2vh] font-semibold uppercase text-center"
              >
                {reward?.value} {reward?.type}
              </div>
            </div>
          </div>
          <div className=" w-full max-w-sm flex flex-1 pb-16 flex-col items-center justify-center space-y-10">
            <div
              className="prose"
              dangerouslySetInnerHTML={{ __html: config?.endScreen?.body }}
            />

            {/* <h3 className="text-center font-bold text-lg">
            Terms and Conditions:
          </h3>
          <ul className="list-disc w-full font-semibold pt-6 px-4 space-y-2">
            <li className="ml-6">
              Lorem Ipsum is simply test industry. Lorem Ipsum is simply test
              industry.
            </li>
            <li className="ml-6">Lorem Ipsum is simply test industry.</li>
          </ul> */}
            <div
              onClick={() => navigate(-1)}
              style={{
                backgroundColor: config?.primaryColor,
              }}
              className="bg-purple-700 fixed bottom-5 flex items-center justify-center btn-3d rounded-full text-center text-white h-12 font-semibold max-w-[260px] w-full"
            >
              Home
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Quiz;
