/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import splash from "../assets/loader.mp4";
import gif from "../assets/loader.gif";
import jwtDecode from "jwt-decode";
import instance from "../extras/baseUrl";
import axios from "axios";

export const SERVER_URL_PLUTUS = "https://api.unicohub.com/v1/game/gameResults";

export const UPDATE_RESULTS_URL_NUDGE =
  "https://pointsystem.api.nudgenow.com/api/v1/post_match/pm/update";

const BASE_URL =
  "https://pointsystem.api.nudgenow.com/api/v1/games/customize/get/";

const fallbackGameId = "659c0be12759d631f9caa11f";
const fallbackToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDFkYjMwYjkyYzU0NzY1ZTM0MmVmZmIiLCJuYW1lIjoiU2hpdmFuc2h1IFR5YWdpIiwiZW1haWwiOiJzaGl2YW5zaHVAdW5pY29odWIuY29tIiwiaWF0IjoxNjc5NjY3OTc5fQ.5NISJ3oodymsED848bvx9kcZaJlEM1VTJmqVGA3NVHg";

const fallbackPlutusToken =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJnYW1lVHlwZSI6ImNhcnJvbSIsInBsYXllcl9uYW1lIjoiSm9obiBEb2UiLCJwbGF5ZXJJZCI6IjEyMzQ1IiwicGxheWVyX2ltYWdlIjoiaHR0cHM6Ly9waWNzdW0ucGhvdG9zLzIwMCIsInBsYXllcjJfbmFtZSI6IlNvbmFsaSIsInBsYXllcjJfaW1hZ2UiOiJodHRwczovL3BpY3N1bS5waG90b3MvMjAwIiwiaW5zdGFuY2VJZCI6IjY0MmU3MmQzOGNlNWQxN2U0MGVlZGZjOCIsImRpZmZpY3VsdHkiOiJlYXN5IiwiaWF0IjoxNzA0NzEzMjE5LCJleHAiOjE3MDQ3MTY4MTl9.SUDr21Z51swOQYmwJK1RjF_PDil-pHLsqlzE1oDQHyc";

const GameWrapper = ({ game }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("nudgeToken") || fallbackToken;
  const payload = urlParams.get("plutusToken") || fallbackPlutusToken;
  const gameId = urlParams.get("gameId") || fallbackGameId;

  const [instanceId, setInstanceId] = useState("");

  const [verified, setVerified] = useState(false);

  const unityContext = useUnityContext({
    loaderUrl: `https://scripts.nudgenow.com/games/${game}/build/${game}.loader.js`,
    dataUrl: `https://scripts.nudgenow.com/games/${game}/build/${game}.data`,
    frameworkUrl: `https://scripts.nudgenow.com/games/${game}/build/${game}.framework.js`,
    codeUrl: `https://scripts.nudgenow.com/games/${game}/build/${game}.wasm`,
    productName: `${game}`,
    productVersion: "1.0.6",
    companyName: "Nudge",
  });

  const {
    unityProvider,
    isLoaded,
    sendMessage,
    addEventListener,
    removeEventListener,
  } = unityContext;

  // We'll round the loading progression to a whole number to represent the
  // percentage of the Unity Application that has loaded.

  // We'll use a state to store the device pixel ratio.
  const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio
  );

  const handleApiClose = async (instanceId, winnerId) => {
    console.log(instanceId, winnerId, "INSTANCE");
    try {
      // Send form data on the gameResult endpoint

      await axios.post(
        SERVER_URL_PLUTUS,
        {
          gameInstanceId: instanceId,
          winnerId,
        },
        {
          headers: {
            Authorization: `Bearer ${payload}`,
          },
        }
      );

      await axios.post(
        UPDATE_RESULTS_URL_NUDGE,
        {
          instanceId,
          winnerId,
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      window.close();
      return true;
    } catch (err) {
      console.log(err);
      window.close();
      return false;
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        const { instanceId } = decodeToken();
        setInstanceId(instanceId);
        onGameOver(instanceId);
        window?.app?.postMessage("close");
        console.log("ALERTS");
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  function iOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  const handleChangePixelRatio = useCallback(
    function () {
      // A function which will update the device pixel ratio of the Unity
      // Application to match the device pixel ratio of the browser.
      const updateDevicePixelRatio = function () {
        setDevicePixelRatio(window.devicePixelRatio);
      };
      // A media matcher which watches for changes in the device pixel ratio.
      const mediaMatcher = window.matchMedia(
        `screen and (resolution: ${devicePixelRatio}dppx)`
      );
      // Adding an event listener to the media matcher which will update the
      // device pixel ratio of the Unity Application when the device pixel
      // ratio changes.
      mediaMatcher.addEventListener("change", updateDevicePixelRatio);
      return function () {
        // Removing the event listener when the component unmounts.
        mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
      };
    },
    [devicePixelRatio]
  );

  const decodeToken = () => {
    const decoded = jwtDecode(payload);
    return decoded;
  };

  const verifyToken = async () => {
    try {
      const decoded = jwtDecode(token);
      console.log(decoded, "TOKEn");
      const res = await instance.post("/clients/verify", {
        clientId: decoded?._id,
      });
      if (res.data?.status === "success") {
        setVerified(true);
      }
      console.log("Client Verified");
    } catch (err) {
      console.log(err);
    }
  };

  const onGameOver = (instanceId, data = "bot") => {
    handleApiClose(instanceId, data);
    console.log(data, "DATA");
    window?.ReactNativeWebView?.postMessage("close");
    window?.app?.postMessage("close");
    window?.FlutterInappwebview?.callHandler("app", "close");
  };

  const handleGameOver = useCallback(onGameOver, []);

  useEffect(() => {
    addEventListener("GameOver", handleGameOver);
    return () => {
      removeEventListener("GameOver", handleGameOver);
    };
  }, [addEventListener, removeEventListener, handleGameOver]);

  useEffect(() => {
    if (token) {
      console.log(token);
      verifyToken();
    }
  }, [token]);

  useEffect(() => {
    if (isLoaded) {
      //   setTimeout(() => {
      try {
        const {
          gameType,
          player_name,
          playerId,
          player_image,
          player2_name,
          player2_image,
          instanceId,
          difficulty,
          iat,
          exp,
        } = decodeToken();
        sendMessage(
          "GameApiCalls",
          "GetData",
          JSON.stringify({
            gameType: gameType,
            player_name: player_name,
            playerId: playerId,
            player_image: player_image,
            player2_name: player2_name,
            player2_image: player2_image,
            instanceId: instanceId,
            difficulty: difficulty,
            iat: iat,
            exp: exp,
          })
        );

        sendMessage(
          "GameCustomization",
          "SetGameProperties",
          JSON.stringify({
            token: fallbackToken,
            url: BASE_URL + gameId,
          })
        );
      } catch (err) {
        console.error(err);
        window?.ReactNativeWebView?.postMessage("close");
        window?.app?.postMessage("close");
        window?.FlutterInappwebview?.callHandler("app", "close");
      }
    }
  }, [isLoaded, payload]);

  function handleMessageFromUnity(message) {
    console.log(message, "MESS");
  }

  return (
    <div className="overflow-hidden h-screen">
      {isLoaded === false && (
        // We'll conditionally render the loading overlay if the Unity
        // Application is not loaded.
        <div className="h-screen w-screen flex items-center justify-center">
          {iOS() ? (
            <img className="max-w-xs" src={gif} alt="" />
          ) : (
            <video
              className="max-w-xs"
              src={splash}
              playsInline
              autoPlay
              muted
              loop
            />
          )}
        </div>
      )}
      {verified && (
        <Unity
          unityProvider={unityProvider}
          style={{ width: "100vw", height: "100vh" }}
          devicePixelRatio={window.devicePixelRatio}
          onMessage={handleMessageFromUnity}
        />
      )}
      {/* } */}
    </div>
  );
};

export default GameWrapper;
