import React, { useEffect, useState } from "react";
import HowToPlay from "./HowToPlay";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";
import { Link, useNavigate } from "react-router-dom";
import bg1 from "../../assets/bg1.png";
import axios from "axios";
import instance from "../../extras/baseUrl";
import ConfettiExplosion from 'react-confetti-explosion';
import { motion } from "framer-motion";
import Loader from "../../components/Loader";
import { hexToRGBA, lightenColor } from "../../extras/utils";
const QNA = [
  {
    question: "This is where the question1 is?",
    image1:
      "https://images.unsplash.com/photo-1597274324473-c3ced481af9c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    image2:
      "https://images.unsplash.com/photo-1635975229704-0a420e777a56?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YnVnYXR0aXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
  },
  {
    question: "This is where the question2 is?",
    image1:
      "https://images.unsplash.com/photo-1616615965190-08884c4d85c4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8YWRkaWRhc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
    image2:
      "https://images.unsplash.com/photo-1597045566677-8cf032ed6634?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bmlrZSUyMGFpciUyMGpvcmRhbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
  },
];

const ThisOrThat = () => {
  const { width, height } = useWindowSize();

  const [active, setActive] = useState(0);
  const [answers, setAnswers] = useState({});
  const [completed, setCompleted] = useState(false);
  const [howToPlay, setHowToPlay] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [coins, setCoins] = useState(0);

  // Navigation Hook
  const navigate = useNavigate();

  // useState hooks
  const [config, setConfig] = useState({});
  const [response, setResponse] = useState([]);
  const [reward, setReward] = useState({ value: 0 });

  // get query params from the url
  const urlParams = new URLSearchParams(window.location.search);
  const gameZoneId = urlParams.get("gameZoneId");
  const nudgeUserId = urlParams.get("nudgeUserId");
  const gameSettingId = urlParams.get("gameSettingId");
  const key = urlParams.get("key");
  const token = urlParams.get("token");
  const [stats, setStats] = useState([]);

  const getStats = async () => {
    try {
      const res = await instance.get(
        `/this_or_that/stats?gameZoneId=${gameZoneId}&gameSettingId=${gameSettingId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(res.data.data, "STATS");
      setStats(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getGameSettings = async () => {
    try {
      const res = await instance.get(
        `/gamezone/gameSettings?gameSettingId=${gameSettingId}&gameKey=${key}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setConfig(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const PreRequest = async () => {
    try {
      const payload = {
        nudgeUserId: nudgeUserId,
        gameZoneId: gameZoneId,
        gameSettingId: gameSettingId,
      };
      const res = await instance.post(
        `/this_or_that/pre?type=gamezone`,
        payload,
        {
          headers: {
            Authorization: `Bearerv ${token}`,
          },
        }
      );
      console.log(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const SubmitResponse = async (responseValue) => {
    try {
      console.log(responseValue);
      const payload = {
        nudgeUserId: nudgeUserId,
        gameZoneId: gameZoneId,
        gameSettingId: gameSettingId,
        responses: responseValue,
      };
      const res = await instance.post(
        `/this_or_that/post?type=gamezone`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(res.data);
      setReward(res.data.data.reward);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (token && nudgeUserId && gameSettingId && gameZoneId) {
      PreRequest();
      getStats();
    }
  }, [token, nudgeUserId, gameSettingId, gameZoneId]);

  useEffect(() => {
    if (token && key && gameSettingId) {
      getGameSettings();
    }
  }, [token, key, gameSettingId]);

  const handleClick = async (value, option) => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    setCoins(
      (prev) =>
        prev +
        config.questions[active].reward?.quantity *
          config.questions[active].reward?.rewardId?.value
    );
    const payload = {
      question: config.questions[active].question,
      option: option,
      reward: config.questions[active].reward,
    };
    var newResponses = response;
    newResponses.push(payload);
    setResponse(newResponses);
    console.log(payload, response);
    setAnswers({
      ...answers,
      [active]: value,
    });

    if (active + 1 !== config?.questions?.length) {
      setTimeout(() => {
        setActive((prev) => prev + 1);
        setSubmitting(false);
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 800);
      }, 1600);
      // setTime
      return;
    }
    setTimeout(async () => {
      await SubmitResponse(newResponses);
      setCompleted(true);
    }, 1000);
  };

  const handleSkip = () => {
    if (active + 1 !== config?.questions?.length) {
      setActive((prev) => prev + 1);
      return;
    }
    setCompleted(true);
    SubmitResponse(response);
  };

  const calcuatePercentage = (index, option) => {
    console.log(
      stats[index][option]?.noOfUsers +
        (response[index]["option"] === stats[index][option]["option"] ? 1 : 0),
      option
    );
    return (
      ((stats[index][option]?.noOfUsers +
        (response[index].option === stats[index][option]["option"] ? 1 : 0)) /
        (stats[index]?.option1?.noOfUsers +
          stats[index]?.option2?.noOfUsers +
          1)) *
      100
    ).toFixed(0);
  };

  useEffect(() => {
    if (!howToPlay) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 800);
    }
  }, [howToPlay]);

  useEffect(() => {
    window?.ReactNativeWebView?.postMessage(window.location.href);
  }, []);

  if (howToPlay) {
    return <HowToPlay settings={config?.startScreen} setShow={setHowToPlay} />;
  }

  return !completed ? (
    <motion.div
      // initial={{ x: "100%" }}
      // animate={{ x: 0, transition: { duration: 0.4 } }}
      // exit={{ x: "-100%", transition: { duration: 0.4 } }}
      className="flex overflow-hidden font-fieldwork10 flex-col relative select-none bg-[#6e6e6e23] bg-no-repeat max-w-lg mx-auto h-screen items-center w-full bg-center bg-contain pb-2 space-y-4"
      style={{
        backgroundColor: config?.questions[active]?.bgColor,
        backgroundImage: "url(" + config?.questions[active]?.bgImageUrl + ")",
      }}
    >
      <Loader bgColor={config?.questions[active]?.bgColor} isLoading={isLoading} />
      {/* Question No counts */}
      <div
        style={{
          backgroundColor: config?.questions[active]?.bgColor,
        }}
        className="flex relative justify-center items-center w-full px-2 pt-6 pb-2"
      >
        {/* <div
          style={{
            color: config?.primaryColor,
            backgroundColor: lightenColor(config?.primaryColor, 100),
          }}
          className="bg-purple-200 text-purple-600 px-3 rounded-md font-medium text-base z-10 absolute left-2 flex items-center"
        >
          {coins}{" "}
          <img
            className="h-6 w-6 object-contain"
            src="/assets/STUMBL-coin.gif"
            alt=""
          />
        </div> */}
        <div
          style={{
            color: config?.primaryColor,
            backgroundColor: lightenColor(config?.primaryColor, 100),
          }}
          className="bg-purple-200 text-purple-600 px-3 rounded-md font-medium text-base"
        >
          {active + 1}/{config?.questions?.length}
        </div>
        <button
          onClick={handleSkip}
          style={{
            color: config?.primaryColor,
          }}
          className="z-10 absolute right-2 ml-auto text-purple-600 text-sm"
          // className=" text-purple-600 text-sm"
        >
          SKIP
        </button>
      </div>
      {/* Question */}
      <div className="font-bold text-xl">
        {config?.questions[active]?.question}
      </div>
      <div className="h-[calc(100vh-65px)] flex flex-col w-full rounded-lg">
        {config?.questions[active]?.image1Url && (
          <img
            className="h-1/2 w-full rounded-tl-lg  rounded-tr-lg object-cover"
            src={config?.questions[active]?.image1Url}
            alt=""
          />
        )}
        {config?.questions[active]?.image2Url && (
          <img
            className="h-1/2 w-full object-cover"
            src={config?.questions[active]?.image2Url}
            alt=""
          />
        )}
      </div>
      <div
        style={{
          top: `${config?.questions[active]?.yOffset || 50}%`,
          scale: `${config?.questions[active]?.scale || 100}%`,
        }}
        className="absolute  -translate-y-1/2 top-0 left-0 right-0 flex justify-center w-full pb-6"
      >
        <div className="px-4 w-full">
          <div className="h-20 shadow-sm relative w-full bg-white rounded-xl flex">
            {!answers[active] && (
              <>
                <div
                  onClick={() =>
                    handleClick(1, config?.questions[active].option1)
                  }
                  className="w-full text-3xl text-gray-800 text-center flex justify-center items-center "
                >
                  <span className=" gradient-text">
                    {config?.questions[active].option1}
                  </span>
                </div>
                <div className="h-full w-[2px] bg-gray-300" />
                <div
                  onClick={() =>
                    handleClick(2, config?.questions[active].option2)
                  }
                  className="w-full text-3xl text-center flex justify-center items-center"
                >
                  <span className="gradient-text2 ">
                    {config?.questions[active].option2}
                  </span>
                </div>
              </>
            )}
            {/* Percentage indicator */}
            <div
              style={{
                width: answers[active] ? "100%" : 0,
              }}
              className="absolute inset-0 overflow-x-hidden flex items-center h-full"
            >
              {/* <div
                className="flex justify-between items-center h-full bg-purple-800 rounded-l-lg transition-all duration-500"
                style={{
                  width: answers[active] ? "10%" : 0,
                }}
              > */}
              {answers[active] && (
                <>
                  <span
                    style={{
                      width: answers[active]
                        ? `${calcuatePercentage(active, "option1")}%`
                        : 0,
                    }}
                    className="text-3xl text-gray-800  w-[inherit] text-center z-10"
                  >
                    <div className="text-sm gradient-text">
                      {config?.questions[active].option1}
                    </div>
                    <div className="px-2">{calcuatePercentage(active, "option1")}%</div>
                  </span>
                </>
              )}
              {/* </div> */}
              {answers[active] && (
                <span className="text-3xl text-gray-800  text-center flex flex-col items-center flex-1 justify-center z-10 ">
                  <div className="text-sm gradient-text2">
                    {config?.questions[active].option2}
                  </div>
                  <div className="px-2">{calcuatePercentage(active, "option2")}%</div>
                </span>
              )}
            </div>
            <div
              style={{
                width: answers[active] ? "100%" : 0,
              }}
              className="absolute inset-0 overflow-x-hidden flex items-center h-full"
            >
              {/* <div
                className="flex justify-between items-center rounded-l-lg"
                style={{
                  width: answers[active] ? "60%" : 0,
                }}
              /> */}

              <div
                className="flex justify-between items-center h-full rounded-l-lg transition-all ease-in-out"
                style={{
                  width: answers[active]
                    ? `${calcuatePercentage(active, "option1")}%`
                    : 0,
                  borderTopRightRadius:
                    answers[active] &&
                    calcuatePercentage(active, "option1") == 100
                      ? "8px"
                      : 0,
                  borderBottomRightRadius:
                    answers[active] &&
                    calcuatePercentage(active, "option1") == 100
                      ? "8px"
                      : 0,
                  transitionDuration: answers[active] === 1 ? "700ms" : "0ms",
                  backgroundColor:
                    answers[active] === 1 ? "#D9DADC" : "inherit",
                }}
              />
              {console.log(answers[active])}
              <div
                className="animation-reverse rounded-r-lg  transition-all duration-700 ease-in-out"
                style={{
                  transform: answers[active] === 2 ? "scaleX(1)" : "scaleX(0)",
                  borderTopLeftRadius:
                    answers[active] === 2 &&
                    calcuatePercentage(active, "option2") == 100
                      ? "8px"
                      : 0,
                  borderBottomLeftRadius:
                    answers[active] === 2 &&
                    calcuatePercentage(active, "option2") == 100
                      ? "8px"
                      : 0,
                  width:
                    answers[active] === 2
                      ? `${calcuatePercentage(active, "option2")}%`
                      : 0,
                  backgroundColor: answers[active] === 2 ? "#D9DADC" : "none",
                }}
              />
              {console.log(
                answers[active] === 2 &&
                  console.log(calcuatePercentage(active, "option2") == 100)
              )}
            </div>
          </div>
          {/* <div className="bg-white py-5 px-4 w-full rounded-xl space-y-4">
            <div
              onClick={() => handleClick(1)}
              className="cursor-pointer bg-[#efefef] w-full h-12 rounded-lg relative flex items-center"
            >
              <span className="z-50 px-2 text-2xl">👉</span>
              <div
                className="absolute inset-0 h-full bg-purple-800 rounded-l-lg transition-all duration-500"
                style={{
                  width: answers[active] ? "40%" : 0,
                }}
              ></div>
              {answers[active] && (
                <div className="absolute flex justify-end inset-0 text-xl items-center font-bold px-4">
                  40%
                </div>
              )}
            </div>
            <div
              onClick={() => handleClick(2)}
              className="cursor-pointer bg-[#efefef] w-full h-12 rounded-lg relative flex items-center"
            >
              <span className="z-50 px-2 text-2xl">👈</span>
              <div
                className="absolute inset-0 h-full bg-purple-800 rounded-lg transition-all duration-500"
                style={{
                  width: answers[active] ? "100%" : 0,
                }}
              ></div>
              {answers[active] && (
                <div className="absolute flex text-white justify-end inset-0 text-xl items-center font-bold px-4">
                  100%
                </div>
              )}
            </div>
          </div> */}
        </div>
      </div>
    </motion.div>
  ) : (
    <>
      {reward?.value !== 0 && completed && (
        <Confetti
          height={height - 10}
          width={width - 20}
          recycle={false}
          tweenDuration={100}
          numberOfPieces={300}
        />
      )}
      <motion.div
        // initial={{ x: "100%" }}
        // animate={{ x: 0, transition: { duration: 0.4 } }}
        // exit={{ x: "-100%", transition: { duration: 0.4 } }}
        style={{
          backgroundColor: config?.endScreen?.bgColor,
          backgroundImage: "url(" + config?.endScreen?.bgImageUrl + ")",
        }}
        className="flex bg-no-repeat bg-center bg-contain font-inter flex-col items-center select-none max-w-lg mx-auto min-h-screen space-y-6"
      >
        <div className="flex flex-col items-center justify-between w-full select-none max-w-lg mx-auto min-h-screen py-[5vh] px-4 space-y-6">
          <div className="flex flex-col items-center pt-6 space-y-6">
            <div className="flex flex-col items-center  space-y-2">
              {completed && (
                <>
                  {reward?.value != 0 ? (
                    <h1
                      style={{
                        color: config?.primaryColor,
                      }}
                      className="text-[3.6vh] font-semibold text-purple-800"
                    >
                      {config?.endScreen?.heading}
                    </h1>
                  ) : (
                    <h1
                      style={{
                        color: config?.primaryColor,
                      }}
                      className="text-[3.6vh] font-semibold text-purple-800"
                    >
                      {config?.endScreen?.lostHeading}
                    </h1>
                  )}
                </>
              )}
              <div className="w-full max-h-[26vh] h-full">
                <img
                  className="w-full max-h-[26vh] object-contain"
                  src={config?.endScreen?.imageUrl}
                  alt=""
                />
              </div>
            </div>
            <div className="space-y-1">
              <h3 className="text-center text-[2.4vh] font-bold">
                {config?.endScreen?.subHeading}
              </h3>
              <div
                style={{
                  color: config?.primaryColor,
                }}
                className="text-purple-800 text-[2vh] font-semibold uppercase text-center"
              >
                {reward?.value} {reward?.type}
              </div>
            </div>
          </div>
          <div className=" w-full max-w-sm flex flex-1 pb-16 flex-col items-center justify-center space-y-10">
            <div
              className="prose"
              dangerouslySetInnerHTML={{ __html: config?.endScreen?.body }}
            />

            {/* <h3 className="text-center font-bold text-lg">
            Terms and Conditions:
          </h3>
          <ul className="list-disc w-full font-semibold pt-6 px-4 space-y-2">
            <li className="ml-6">
              Lorem Ipsum is simply test industry. Lorem Ipsum is simply test
              industry.
            </li>
            <li className="ml-6">Lorem Ipsum is simply test industry.</li>
          </ul> */}
            <div
              onClick={() => navigate(-1)}
              style={{
                backgroundColor: config?.primaryColor,
              }}
              className="bg-purple-700 fixed bottom-5 flex items-center justify-center btn-3d rounded-full text-center text-white h-12 font-semibold max-w-[260px] w-full"
            >
              Home
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default ThisOrThat;
