import React, { useEffect, useState } from "react";
import instance from "../extras/baseUrl";
import XIcon from "@heroicons/react/24/outline/XMarkIcon";

const Survey = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const gameSettingId = urlParams.get("gameSettingId");
  const campaignId = urlParams.get("campaignId");
  const clientId = urlParams.get("clientId");
  const nudgeUserId = urlParams.get("nudgeUserId");
  const token = urlParams.get("token");
  const orderId = urlParams.get("orderId");

  const [settings, setSettings] = useState({});
  const [loading, setLoading] = useState(true);
  const [completed, setCompleted] = useState(false);
  const [qNo, setQNo] = useState(0);
  const [answers, setAnswers] = useState([]);

  const [completedStat, setCompletedStat] = useState({
    tracker: false,
    settings: false,
    stats: false,
  });

  const loadSettings = async () => {
    setLoading(true);
    try {
      const res = await instance.get(
        `/game_settings?gameKey=npsSurvey&gameSettingId=${gameSettingId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const gameSetting = res.data.data.gameSetting;
      setSettings(res.data.data.gameSetting);
      const options = gameSetting?.tasks?.map((q) => ({
        question: q.question,
        option: q.optionsType === "rating" ? null : [],
      }));
      setAnswers(options);
      setLoading(false);
      setCompletedStat((prev) => ({
        ...prev,
        ["settings"]: true,
      }));
    } catch (err) {
      console.log(err);
      window?.ReactNativeWebView?.postMessage("close");
      window?.app?.postMessage("close");
      window?.FlutterInappwebview?.callHandler("app", "close");
    }
  };

  const eventTracker = async (responded) => {
    const res = instance.post(
      "/events/survey/track",
      {
        clientId: clientId,
        campaignId: campaignId,
        responded: responded,
        nudgeUserId: nudgeUserId,
        props: {
          orderId: orderId,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(await (await res).data);
    setCompletedStat((prev) => ({
      ...prev,
      ["tracker"]: true,
    }));
  };

  useEffect(() => {
    if (clientId && campaignId && !completedStat.tracker) {
      eventTracker(false);
    }

    if (gameSettingId !== null && !completedStat.settings) {
      loadSettings();
    }
  }, [gameSettingId, clientId, campaignId]);

  console.log(answers);

  const RenderOptionType = ({ type }) => {
    switch (type) {
      case "rating":
        return <StarRating />;
      case "options":
        return <Option />;
      default:
        return null;
    }
  };

  if (loading) {
    return <>Loading...</>;
  }

  const close = () => {
    window?.ReactNativeWebView?.postMessage("close");
    window?.app?.postMessage("close");
    window?.FlutterInappwebview?.callHandler("app", "close");
  };

  const StarRating = () => {
    const [hover, setHover] = useState(null);
    const [rating, setRating] = useState(null);

    const question = settings.tasks[qNo].question;

    const handleClick = (index) => {
      const value = index + 1;
      setRating(value);
      let temp = [...answers];
      temp[qNo].option = value;
      setAnswers(temp);
      if (value >= 3) {
        setQNo(2);
        return;
      }
      setQNo(1);
      return;
    };

    return (
      <div className="flex flex-col items-center">
        <div className="text-center">Choose Rating</div>
        <div
          className="text-2xl text-center font-semibold"
          style={{
            color: settings.props.question.fontColor,
            fontWeight: settings?.props?.question?.fontStyle,
          }}
        >
          {question}
        </div>
        <div className="flex flex-row gap-x-4">
          {[...Array(5)].map((star, index) => (
            <div
              key={index}
              className={
                index + 1 <= (hover || rating)
                  ? "text-yellow-400"
                  : "text-slate-400"
              }
              onClick={() => handleClick(index)}
              onMouseEnter={() => setHover(index + 1)}
              onMouseLeave={() => setHover(rating)}
            >
              <span key={index} className="cursor-pointer text-5xl">
                &#9733;
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const Option = () => {
    const question = settings.tasks[qNo].question;
    const options = settings.tasks[qNo].options;
    const sFColor = settings?.props?.option?.selected?.fontColor;
    const sFStyle = settings?.props?.option?.selected?.fontStyle;
    const sBgColor = settings?.props?.option?.selected?.bgColor;
    const nSFColor = settings?.props?.option?.notSelected?.fontColor;
    const nSFStyle = settings?.props?.option?.notSelected?.fontStyle;
    const nSBgColor = settings?.props?.option?.notSelected?.bgColor;

    const submitButton = settings?.props?.submitButton;

    const [hover, setHover] = useState(null);
    const [error, setError] = useState(null);

    const handleClick = (index, option) => {
      console.log(qNo);
      setError(null)
      if (answers[qNo]?.option?.includes(option)) {
        let temp = [...answers];
        temp[qNo].option = temp[qNo].option.filter((opt) => opt !== option);
        setAnswers(temp);
      } else {
        let temp = [...answers];
        temp[qNo].option = [...temp[qNo].option, option];
        console.log(temp);
        setAnswers(temp);
      }
    };

    const submit = async () => {
      const payload = {
        clientId: clientId,
        campaignId: campaignId,
        userId: nudgeUserId,
        rewardId: "",
        props: {
          orderId: orderId,
        },
        tasks: answers,
      };

      try {
        const res = await instance.post("post_game/survey", payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(res.data);
      } catch (error) {
        console.error(error);
      }
    };

    const handleSubmit = () => {
      if (answers[qNo]?.option?.length == 0) {
        setError('Select an option to submit survey')
        return
      }
      setCompleted(true);
      submit();
      eventTracker(true);
      // setTimeout(() => {
      window?.ReactNativeWebView?.postMessage({message: "close", data: answers[0]});
      window?.app?.postMessage( "close");
      window?.rating?.postMessage(answers[0]?.option);
      window?.FlutterInappwebview?.callHandler("app",{message: "close", rating: answers[0]});
      window?.FlutterInappwebview?.callHandler("rating", answers[0]);
      // }, 3000);
    };  

    return (
      <>
        <div className="space-y-2 flex flex-col items-center">
          {/* <div className="text-center">Choose Rating</div> */}
          <div
            className="text-2xl text-center font-semibold pb-6"
            style={{
              color: settings.props.question.fontColor,
              fontWeight: settings?.props?.question?.fontStyle,
            }}
          >
            {question}
            {
              error &&
            <div className="text-red-500 text-base font-normal mt-2">
              {error}
            </div>
            }
          </div>
        </div>
        <div className="flex flex-col gap-y-4 space-y-4 items-center w-full">
          {options.map((option, index) => {
            return (
              <div
                key={index}
                onClick={() => handleClick(index, option)}
                // onMouseEnter={() => setHover(index)}
                // onMouseLeave={() => setHover(null)}
                className={`w-[80%] max-w-xs cursor-pointer py-3 px-4 text-center rounded-full border-2 font-semibold`}
                style={{
                  color: answers[qNo]?.option?.includes(option)
                    ? sFColor
                    : nSFColor,
                  fontWeight: answers[qNo]?.option?.includes(option)
                    ? sFStyle
                    : nSFStyle,
                  backgroundColor:
                    answers[qNo]?.option?.includes(option) || hover == index
                      ? sBgColor
                      : nSBgColor,
                  borderColor: sBgColor,
                }}
              >
                {option}
              </div>
            );
          })}
          {/* {answers[qNo]?.option?.length != 0 && ( */}
            <button
              onClick={handleSubmit}
              // disabled={answers[qNo]?.option?.length == 0}
              type="button"
              className="mt-6 py-3 px-4 rounded-full w-40 outline-none border-none focus:outline-none focus:border-none"
              style={{
                backgroundColor: submitButton.buttonColor,
                color: submitButton.textColor,
                opacity: answers[qNo]?.option?.length == 0 ? 0.4 : 1
              }}
            >
              Submit
            </button>
          {/* )} */}
        </div>
        {/* <StarRating /> */}
      </>
    );
  };

  return (
    <div
      className="min-h-[100vh] py-8 px-10 flex flex-col items-center justify-center gap-y-4"
      style={{
        backgroundColor: settings?.props?.bgColor,
      }}
    >
      {/* <div className="absolute top-6 right-6">
        <XIcon onClick={close} className="w-6 h-6" />
      </div> */}
      {!completed ? (
        // null
        <RenderOptionType type={settings.tasks[qNo].optionsType} />
      ) : (
        <div className="space-y-2 flex flex-col items-center">
          <div className="text-center text-4xl font-semibold">
            {settings?.props?.lastScreenHeading}
          </div>
          <div className="text-base text-center text-slate-500">
            {settings?.props?.lastScreenDesc}
          </div>
        </div>
      )}
    </div>
  );
};

export default Survey;
