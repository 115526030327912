import React, { useEffect, useState } from "react";
import './HowToPlay.css'
import { motion } from "framer-motion";
import Loader from "../../components/Loader";

const HowToPlay = ({ setShow, settings }) => {

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 800)
  }, [])

  return (
    <motion.div 
    className="overflow-hidden max-h-screen"
    // initial={{ x: '100%' }}
    // animate={{ x: 0, transition: { duration: 0.4 } }}
    // exit={{ x: '-100%', transition: { duration: 0.4 } }}
    >
      <Loader isLoading={isLoading} />
      <div 
      className="flex flex-col bg-center bg-cover bg-no-repeat items-center justify-between max-w-lg mx-auto h-full min-h-screen  w-full space-y-8 py-6 px-6 pt-12 pb-16"
      style={{
        backgroundColor: settings?.bgColor,
        backgroundImage: "url(" + settings?.bgImageUrl + ")",
      }}
      >
        <div className="space-y-8">
          <h1 className="text-3xl font-bold text-purple-700 text-center">
            { settings?.heading}
          </h1>
          <div className="prose"
          dangerouslySetInnerHTML={{__html: settings?.subHeading}}
          >

          </div>
          {/* <ul className="list-disc space-y-8 px-4  font-semibold"> */}
            {/* {
              settings?.subHeading?.split('.')?.map((item, index) => (
              <li key={index} className="ml-4">{item}
              </li>
              ))
            } */}
            {/* <li className="ml-4">
              Lorem Ipsum is simply dummy text of the printing and type setting
              industry.
            </li>
            <li className="ml-4">
              Lorem Ipsum is simply dummy text of the printing and type setting
              industry.
            </li> */}
          {/* </ul> */}
        </div>
        {/* <div className="flex items-center justify-center">
          <button className="btn" id="foot">
            <button className="btn button-os">
              <a href="#">BUTTON</a>
            </button>
          </button>
        </div> */}
        <button
          onClick={() => setShow(false)}
          className="bg-purple-700 btn-3d rounded-full text-center text-white h-12 font-semibold max-w-[260px] w-full"
        >
          Play
        </button>
        {/* <img
          className="max-w-[160px] w-full pt-6"
          src="/assets/Coin-Rotate2.png"
          alt=""
        /> */}
      </div>
    </motion.div>
  );
};

export default HowToPlay;
